import {
  PAID_COURSE_TYPE,
  FREE_COURSE_TYPE,
  SEARCH,
  CATEGORY,
  SEARCHENGINE,
  COURSETYPE,
  COURSEDURATION,
  COURSEPAYMENTTYPE,
  COURSERATING_MIN,
  COURSERATING_MAX,
  COURSEDURATIONLIST,
  COURSEPAYMENTTYPELIST,
  COURSERATINGLIST,
} from "../Types/courseTypes";

const initialState = {
  paidCourse: "",
  freeCourse: "",
  inputValue: "",
  categoryValue: "",
  searchEngine: "",
  courseType: "",
  courseDurationList: [],
  courseDuration: "",
  coursePaymentTypeList: ["both", "free", "paid"],
  coursePaymentType: "both",
  courseRatingList: [0, 1, 2, 3, 4, 5],
  courseRating: {
    min: 0,
    max: 5,
  },
};

const courseReducer = (state = initialState, action) => {
  //console.log("helooooo------------->    " + action.payload, action.type);
  switch (action.type) {
    case PAID_COURSE_TYPE:
      return {
        ...state,
        paidCourse: action.payload,
      };
    case FREE_COURSE_TYPE:
      return {
        ...state,
        freeCourse: action.payload,
      };

    case SEARCH:
      return {
        ...state,
        inputValue: action.payload,
      };
    case CATEGORY:
      return {
        ...state,
        categoryValue: action.payload,
      };
    case SEARCHENGINE:
      return {
        ...state,
        searchEngine: action.payload,
      };
    case COURSETYPE:
      return {
        ...state,
        courseType: action.payload,
      };
    case COURSEDURATION:
      return {
        ...state,
        courseDuration: action.payload,
      };
    case COURSEDURATIONLIST:
      return {
        ...state,
        courseDurationList: action.payload,
      };
    case COURSEPAYMENTTYPELIST:
      return {
        ...state,
        coursePaymentTypeList: action.payload,
      };
    case COURSEPAYMENTTYPE:
      return {
        ...state,
        coursePaymentType: action.payload,
      };

    case COURSERATINGLIST:
      return {
        ...state,
        courseRatingList: action.payload,
      };

    case COURSERATING_MIN:
      if (action.payload > state.courseRating.max) {
        return {
          ...state,
          courseRating: {
            ...state.courseRating,
            min: action.payload,
            max: action.payload,
          },
        };
      } else {
        return {
          ...state,
          courseRating: { ...state.courseRating, min: action.payload },
        };
      }
    case COURSERATING_MAX:
      if (state.courseRating.min > action.payload) {
        return {
          ...state,
          courseRating: {
            ...state.courseRating,
            min: action.payload,
            max: action.payload,
          },
        };
      } else {
        return {
          ...state,
          courseRating: { ...state.courseRating, max: action.payload },
        };
      }

    //return Object.assign({}, state, { inputValue: action.text });

    default:
      return state; // return state
  }
};

export default courseReducer;
