import React, { useState, useEffect } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import UserService from "../../services/UserService";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import adminServices from "../../services/adminServices";
import learnerService from "../../services/learnerService";
import service, { USER_API } from "../../services/service";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useHistory } from 'react-router-dom';
import { Bar, Pie } from "react-chartjs-2";
import "./otherReport.css";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};



function OtherReports() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const history = useHistory();

  const um_api = USER_API;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


  const [getLearnerData, setlearnerData] = useState([]);
  useEffect(() => {
    LearnersDatas();
  }, []);

  const [getCourseStatusList, setCourseStatusList] = useState([]);
  const [getAllCourse, setAllCourse] = useState([]);
  const [getMaleCount, setMaleCount] = useState([]);
  const [getFemaleCount, setFemaleCount] = useState([]);
  const [getSelectedReport, setSelectedReport] = useState();
  const [getIndiaCount, setIndiaCount] = useState([]);
  const [getForeignCount, setForeignCount] = useState([]);

  useEffect(() => {
    service
      .getCourseandFeesStatus()
      .then((res) => {
         //console.log(res.data);
        setCourseStatusList(res.data);

        res.data.map((data) =>{

            const newCourseName = data.courseName;

            setAllCourse((prevCourseNames) => [...prevCourseNames, newCourseName]);
            setMaleCount((prevmalecount) => [...prevmalecount, data.malecount]);
            setFemaleCount((prevFemaleCount) => [...prevFemaleCount, data.femalecount]);
            setIndiaCount((prevIndiaCount) => [...prevIndiaCount, data.indiacount]);
            setForeignCount((prevForeignCount) => [...prevForeignCount, data.foreigncount]);
        })
        
      })
      .catch((err) => {});
  }, []);

  let filteredItems = getCourseStatusList.sort((a, b) => new Date(b.commencementDate) - new Date(a.commencementDate))  ;

  const tableExportExcel = () => {
    var checkData = [];
    const header = ["Course ID", "Course Name", "Male Count", "Female Count"]
    if (getCourseStatusList !== undefined && getCourseStatusList.length !== 0) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;
            
            const instData = [courseId, courseName, malecount, femalecount]
            checkData.push(instData);
        })

        downloadExcel({
            fileName: "Course Wise Gender Report",
            sheet: "Course Wise Gender Report",
            tablePayload: {
                header,
                body: checkData,
            },
        })
    }
  };

  const tableExportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(13);
    const title = "Course Learner List";
    const headers = [["Course ID", "Course Name", "Male Count", "Female Count"]];
    var checkData = [];
    if (getCourseStatusList !== undefined) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;

            const instData = [courseId, courseName, malecount, femalecount]
            checkData.push(instData);
        })

        let content = {
            startY: 50,
            head: headers,
            body: checkData
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save("CourseWiseGenderReport.pdf")
    }
  };
  const tableExportExcel1 = () => {
    var checkData = [];
    const header = ["Course ID", "Course Name", "Indian Student Count", "Foreign Student Count"]
    if (getCourseStatusList !== undefined && getCourseStatusList.length !== 0) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const foreigncount = `${data.foreigncount}`;
            const indiacount = `${data.indiacount}`;
            
            const instData = [courseId, courseName, indiacount, foreigncount]
            checkData.push(instData);
        })

        downloadExcel({
            fileName: "Course Wise Gender Report",
            sheet: "Course Wise Gender Report",
            tablePayload: {
                header,
                body: checkData,
            },
        })
    }
  };

  const tableExportPDF1 = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(13);
    const title = "Course Learner List";
    const headers = [["Course ID", "Course Name", "Indian Student Count", "Foreign Student Count"]];
    var checkData = [];
    if (getCourseStatusList !== undefined) {
        getCourseStatusList.map((data) => {
            const courseId = `${data.courseId}`;
            const courseName = `${data.courseName}`;
            const foreigncount = `${data.foreigncount}`;
            const indiacount = `${data.indiacount}`;

            const instData = [courseId, courseName, indiacount, foreigncount]
            checkData.push(instData);
        })

        let content = {
            startY: 50,
            head: headers,
            body: checkData
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save("CourseWiseGenderReport.pdf")
    }
  };

  const LearnersDatas = () => {
    adminServices
      .getAllLearners()
      .then((resp) => {
        setlearnerData(resp.data);
      })
      .catch((err) => {});
  };


  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  const options1 = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: true,
        position: 'top',
      },
    },
  };
  

//   console.log("getAllCourse", getAllCourse);

  const data = {
    labels: getAllCourse,
    datasets: [
      {
        label: 'Male',
        data: getMaleCount,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Female',
        data: getFemaleCount,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const data1 = {
    labels: getAllCourse,
    datasets: [
      {
        label: 'Indian Student Count',
        data: getIndiaCount,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Foreign Student Count',
        data: getForeignCount,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };


  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div>
              <div className="page-header">
                <h3 className="page-title">Others Reports</h3>
              </div>
              <Col lg="12">
                <div className="card">
                <div className="card">
                <div className="card-body">
                    <div style={{margin:"10px", backgroundColor:"#efefef", padding:"20px"}}>
                    <Form.Group>
                        <label><h5>Select Report</h5></label>
                        <Form.Control
                            onChange={e => {
                                setSelectedReport(e.target.value);
                            }}
                            as='select'
                            placeholder='Select Report'
                        >
                            <option selected>{t('choose')}</option>
                            <option value={"course-wise-gender"}>Course Wise Gender Report</option>
                            <option value={"country-wise"}>Country Wise Report</option>
                          
                           </Form.Control>
                    </Form.Group>
                    </div>
                    
                    {(getSelectedReport == "course-wise-gender") && (<div style={{margin:"30px"}}>
                        
                    <h4> Course Wise Gender Report </h4>

                    <Col lg={{ span: 3, offset: 9 }}>
                  <div className="d-flex px-2">
                    <Button
                      onClick={tableExportExcel}
                      style={{
                        marginRight: "5px",
                        background: "#f0ad4e",
                        border: "0px",
                      }}
                    >
                      {" "}
                      {t("export_to_excel")}{" "}
                    </Button>
                    <Button
                      onClick={tableExportPDF}
                      style={{ background: "#f0ad4e", border: "0px" }}
                    >
                      {" "}
                      {t("export_to_pdf")}{" "}
                    </Button>
                  </div>
                </Col>

                    {/* <Bar data={dataBar} options={optionBar} /> */}
                    <Bar data={data} options={options} />
                    </div>)}
                   {(getSelectedReport == "country-wise") && ( <div style={{margin:"30px"}}>
                    <h4> Country Wise Report </h4>

                    <Col lg={{ span: 3, offset: 9 }}>
                  <div className="d-flex px-2">
                    <Button
                      onClick={tableExportExcel1}
                      style={{
                        marginRight: "5px",
                        background: "#f0ad4e",
                        border: "0px",
                      }}
                    >
                      {" "}
                      {t("export_to_excel")}{" "}
                    </Button>
                    <Button
                      onClick={tableExportPDF1}
                      style={{ background: "#f0ad4e", border: "0px" }}
                    >
                      {" "}
                      {t("export_to_pdf")}{" "}
                    </Button>
                  </div>
                </Col>
                    
                    {/* <Bar data={dataBar} options={optionBar} /> */}
                    <Bar data={data1} options={options1} />
                    </div>)}
                </div>
                </div>  </div>
              </Col>
            </div>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default OtherReports;
