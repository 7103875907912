import React, { useEffect, useMemo, useState } from 'react';
import UserService from '../../services/UserService';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import adminServices from '../../services/adminServices';
import { Container, Row, Col, Button, Form, Card, Modal } from 'react-bootstrap';
import StickyMenu from '../../components/common/StickyMenu';
import Footer from './../shared/Footer';
import Navbar from './../shared/Navbar';
import SettingsPanel from './../shared/SettingsPanel';
import Sidebar from './../shared/Sidebar';
import FilterDataTable from '../../pages/instructor/FilterDataTable';
import DataTable from 'react-data-table-component';
import ProgressBar from '@ramonak/react-progress-bar';
import jsPDF from 'jspdf';
import { downloadExcel } from 'react-export-table-to-excel';
import { Circles } from 'react-loader-spinner';
import * as XLSX from 'xlsx';
import { USER_API2 } from '../../services/service';
import service from '../../services/service';
import learnerService from '../../services/learnerService';
import moment from 'moment';
import fileDownload from 'js-file-download';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    }
]

const customStyles = {
    title: {
        style: {
            fontColor: 'red',
            fontWeight: '900',
        }
    },
    headCells: {
        style: {
            fontSize: '17px',
            fontWeight: '500',
            paddingLeft: '0 8px',
            marginLeft: '10px',
        },
    },
    cells: {
        style: {
            fontSize: '15px',
            paddingLeft: '0 8px',
            marginLeft: '10px'
        },
    },
};

const styles = {
    summaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    summaryItem: {
        textAlign: 'center',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        margin: '5px',
        width: '45%',
    },
    summaryLabel: {
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    summaryValue: {
        fontSize: '18px',
        color: '#007BFF',
    },
};

const CourseSummary = () => {


    const currentLanguageCode = Cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {

        UserService.generateToken();
        getAllCourseData();
    }, []);

    const um_api = USER_API2 + "/um_api/";

    const [courseList, setCourseList] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [courseSummaryList, setCourseSummaryList] = useState([]);
    const [filteredList, setFilteredList] = useState();

    const [totalEnrolledCourse, setTotalEnrolledCourse] = useState(0);
    const [totalCompletedCourse, setTotalCompletedCourse] = useState(0);
    const [totalInProgressCourse, setTotalInProgressCourse] = useState(0);
    const [totalGenCert, setTotalGenCert] = useState(0);
    const [totalNotStartedYet, setTotalNotStartedYet] = useState(0);

    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );


    const [selectedRow, setSelectedRow] = useState();
    const [moreDetailsModalShow, setMoreDetailsModalShow] = useState(false);
    const [selectedState, setSelectedState] = useState();

    const [quizReportList, setQuizReportList] = useState([]);

    const [userCourseContentReportList, setuserCourseContentReportList] = useState([]);

    const [timeSpendData, setTimeSpendData] = useState([]);
    const [timeSpendReportShow, setTimeSpendReportShow] = useState(false);
    const [timeSpendDateRange, setTimeSpendDateRange] = useState({
        fromTimeSpendDate: '',
        toTimeSpendDate: '',
    });
    const timeSpendHandler = (e) => {
        setTimeSpendDateRange({
            ...timeSpendDateRange,
            [e.target.name]: e.target.value
        })
    }
    const [timeSpendDateRangeError, setTimeSpendDateRangeError] = useState({
        fromDateError: '',
        toDateError: '',
    });

    const timeSpendDateRangeValidate = () => {
        let fromDateErr = ''
        let toDateErr = ''

        if (!timeSpendDateRange.fromTimeSpendDate) {
            fromDateErr = t('date_required')
        }
        if (!timeSpendDateRange.toTimeSpendDate) {
            toDateErr = t('date_required')
        }

        if (timeSpendDateRange.fromTimeSpendDate && timeSpendDateRange.toTimeSpendDate) {
            const from = new Date(timeSpendDateRange.fromTimeSpendDate).getTime();
            const to = new Date(timeSpendDateRange.toTimeSpendDate).getTime();
            if (from > to) {
                fromDateErr = t('should_be_less_than_todate')
            }
        }
        if (fromDateErr || toDateErr) {
            setTimeSpendDateRangeError({
                ...timeSpendDateRangeError,
                fromDateError: fromDateErr,
                toDateError: toDateErr,
            })
            return false
        }
        return true
    }


    const [contentAccessData, setContentAccessData] = useState([]);
    const [contentAccessLogShow, setContentAccessLogShow] = useState(false);
    const [contentDateRange, setContentDateRange] = useState({
        fromContentDateRange: '',
        toContentDateRange: '',
    })

    const contentDateRangeHandler = (e) => {
        setContentDateRange({
            ...contentDateRange,
            [e.target.name]: e.target.value,
        })
    }

    const [contentDateRangeError, setContentDateRangeError] = useState({
        fromDateError: '',
        toDateError: '',
    });

    const contentDateRangeValidate = () => {
        let fromDateErr = ''
        let toDateErr = ''

        if (!contentDateRange.fromContentDateRange) {
            fromDateErr = t('Date_required')
        }
        if (!contentDateRange.toContentDateRange) {
            toDateErr = t('should_be_less_than_todate')
        }

        if (contentDateRange.fromContentDateRange && contentDateRange.toContentDateRange) {
            const from = new Date(contentDateRange.fromContentDateRange).getTime();
            const to = new Date(contentDateRange.toContentDateRange).getTime();
            if (from > to) {
                fromDateErr = t('')
            }
        }
        if (fromDateErr || toDateErr) {
            setContentDateRangeError({
                ...contentDateRangeError,
                fromDateError: fromDateErr,
                toDateError: toDateErr,
            })
            return false
        }
        return true
    }


    const [onClickContentSubmit, setOnClickContentSubmit] = useState({
        isLoading: false
    })

    const getCourseSummaryData = () => {
        setTotalEnrolledCourse(0);
        setTotalCompletedCourse(0);
        setTotalInProgressCourse(0);
        setTotalNotStartedYet(0);
        setTotalGenCert(0);
        setLoading(true);
        try {
            if (selectedCourseId) {
                adminServices.getUsersCourseSummaryByCourseId(selectedCourseId)
                    .then((res) => {
                        //console.log(res.data);
                        if (res.status == 200) {
                            setCourseSummaryList(res.data);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        
                        setLoading(false);
                        swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
                    })
            }
        } catch (err) {
            
            setLoading(false);
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
        }
    }

    const getAllCourseData = () => {
        setLoading(true);
        try {
            service.getPublishCourses().then((res) => {
                setCourseList(res.data);
                setLoading(false);
            }).catch((err) => {
                
                setLoading(false);
                swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
            })
        } catch (err) {
            
            setLoading(false);
            swal(`${t('error')}`, `${t('something_wrong_try_again')}`, "error")
        }
    }

    const calculateCourseSummaryData = () => {
        setTotalEnrolledCourse(courseSummaryList.length);

        let completedCount = 0;
        let inProgressCount = 0;
        let notStartedCount = 0;
        let totalGenCert = 0;

        if (courseSummaryList[0]?.totalContent == 0) {
            setTotalCompletedCourse(completedCount);
            setTotalInProgressCourse(inProgressCount);
            setTotalNotStartedYet(notStartedCount);
            setTotalGenCert(0);
        } else {
            courseSummaryList.forEach((user) => {
                // const completed = user.progress === 100
                // const total = parseInt(user.totalContent);

                if (user.progress === 100) {
                    completedCount++;
                } else if (user.progress > 0 && user.progress < 100) {
                    inProgressCount++;
                } else if (user.progress === 0) {
                    notStartedCount++;
                }

                if (user.certDownloadStatus === "GENERATED") {
                    totalGenCert++;
                }
            });

            setTotalEnrolledCourse(courseSummaryList.length)
            setTotalCompletedCourse(completedCount);
            setTotalInProgressCourse(inProgressCount);
            setTotalNotStartedYet(notStartedCount);
            setTotalGenCert(totalGenCert);
        }
    };

    const resetError = () => {
        setTimeSpendDateRangeError({
            ...timeSpendDateRangeError,
            fromDateError: '',
            toDateError: '',
        })

        setContentDateRangeError({
            ...contentDateRangeError,
            fromDateError: '',
            toDateError: '',
        })
    }

    const onClickTimeSpendReportSubmit = () => {
        const condition = timeSpendDateRangeValidate();
        if (condition) {
            learnerService.getLearnerTimeSpend(selectedRow?.userId, selectedRow?.courseId, timeSpendDateRange.fromTimeSpendDate, timeSpendDateRange.toTimeSpendDate)
                .then((resp) => {
                    setTimeSpendReportShow(true);
                    setTimeSpendData(resp.data);
                    resetError();
                }).catch((err) => {
                    
                })
        }
    }

    const onClickContentAcessSubmit = () => {
        if (contentDateRangeValidate()) {
            setContentAccessLogShow(true);
            learnerService.getLearnerContentAccessLog(selectedRow?.userId, selectedRow?.courseId, contentDateRange.fromContentDateRange, contentDateRange.toContentDateRange).then((resp) => {
                setContentAccessLogShow(true);
                setContentAccessData(resp.data);
                resetError();
                setOnClickContentSubmit({ isLoading: false });
                
            }).catch((err) => {
                
            })
        }

    }


    useEffect(() => {
        calculateCourseSummaryData();
    }, [courseSummaryList])


    useEffect(() => {
        getCourseSummaryData();
    }, [selectedCourseId])

    useEffect(() => {
        getFilteredItems();
    }, [courseSummaryList, filterText])


    const getFilteredItems = () => {
        let filteredItems = courseSummaryList?.filter(
            item =>
                item.courseId == selectedCourseId &&
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(filterText.toLowerCase()) !== -1

        );

        setFilteredList(filteredItems);
    }

    const getQuizReport = () => {
        //console.log("userId -> "+userId+" CourseID -> "+courseId);
        service.quizReport(selectedRow.userId, selectedRow.courseId)
            .then((resp) => {
                setQuizReportList(resp.data.assignedQuizzes);
            }).catch((err) => {
            })
    }

    const getUserCourseContentWatchReport = () => {
        service.getUserCourseContentDetails(selectedRow.userId, selectedRow.courseId)
            .then((res) => {
                //console.log(res.data);
                setuserCourseContentReportList(res.data);
            })
            .catch((err) => {
                //console.log("ERROR: ", err);
            })
    }

    const quizfilteredItems = quizReportList?.filter(
        item =>
            item.quizTitle
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const userCourseContentReportfilteredList = userCourseContentReportList?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const timespentfilteredItems = timeSpendData?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const contentAccessfilteredItems = contentAccessData?.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterDataTable
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const toggleModal = () => {
        setMoreDetailsModalShow(!moreDetailsModalShow);
    }

    const handleRowClick = (row) => {
        setSelectedRow(row);
        toggleModal();
        //console.log(row);
    };

    const [getFileURL, setFileURL] = useState(null);
    const [getCertificateGenerateStatus, setCertificateGenerateStatus] = useState(false);

    const handleCertificateView = (userId, courseId, firstName) => {
        let tenantId = 1;
        certificateDownloadFunction(userId, courseId, tenantId, firstName);
        //setCertificateView({ show: true })
    }

    const certificateDownloadFunction = (userId, courseId, tenantId, firstName) => {

        setFileURL(null);
      
        learnerService.toGenerateCertificate(userId, courseId, tenantId)
            .then(res => {
                
                if (res.status == '200') {
                    // return(
                    //     <>
                    //     <a target='_blank' src={`${CERTIFICATION}/certificate/downloadFile/${courseId}/${res.data}`}>test</a>
                    //     </>
                    // )
      
                    learnerService.toDownloadCertificate(courseId, res.data)
                        .then(res => {
                            
                            fileDownload(res.data, `${firstName} Certificate.pdf`);
                            setCertificateGenerateStatus(!getCertificateGenerateStatus);
                        })
                        .catch(err => {
      
                        })
                    //console.log("toGenerateCertificate123", res);
                    
                    //setCertificateUrl(res.data);
      
      
                    // var file = new File([res.data], "tempName.pdf", {
                    //     type: "application/pdf"
                    //   });
                    //   const fileURL = URL.createObjectURL(file);
                    
                    //   setFileURL(fileURL);
                }
      
            }).catch(err => {
                swal(t('error_mesg'), t('try_sometimes'), "error");
            })
      
      }

    const handleMoreDetailsModalClose = () => {
        toggleModal();
        setSelectedRow();
        setTimeSpendData();
        setContentAccessData();
        setContentDateRange();
        setTimeSpendDateRange();
        setQuizReportList();
        setSelectedState();
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait"
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = `${selectedCourse.courseName} Report`;
        const headers = [["User Name", "Email", "Course Progress(%)", "Progress Status", "Certficate Status"]];

        var checkData = courseSummaryList.map((data) => [
            `${data.firstName + data.lastName}`
            , `${data.email}`
            , `${data.progress}`
            , `${data.progressStatus}`
            , `${data.certDownloadStatus}`
        ])
        let content = {
            startY: 50,
            head: headers,
            body: checkData
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save(`${selectedCourse.courseName}-Summary-Report.pdf`)
    }

    const exportExcel = () => {
        //console.log("Excel Clicked");

        var checkData = [];
        const headers = ["User Name", "Email", "Course Progress(%)", "Progress Status", "Certficate Status"];

        courseSummaryList.forEach((data) => {
            const UserName = data.firstName + data.lastName;
            const Email = data.email;
            const CourseProgress = data.progress;
            const ProgressStatus = data.progressStatus;
            const CertficateStatus = data.certDownloadStatus;

            checkData.push([UserName, Email, CourseProgress, ProgressStatus, CertficateStatus]);
        });

        const ws = XLSX.utils.aoa_to_sheet([headers, ...checkData]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, `Course Report`);
        XLSX.writeFile(wb, `${selectedCourse.courseName}-Summary-Report.xlsx`);
    }

    const convertTimestampToDate = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    const columns = [
        {
            name: "Name",
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            wrap: true,
        },
        {
            name: "Email",
            selector: row => row.email,
            wrap: true,
            sortable: true,
        },
        {
            name: "Contents Progress",
            selector: row => row.progress,
            sortable: true,
            wrap: true,
            width: '300px',
            cell: (row) => (
                <Container>
                    <Row>
                        <Col md={12}>
                            <ProgressBar
                                completed={row.progress || 1}
                                baseBgColor="#e0e0de"
                                maxCompleted={100}
                                bgColor={row.progress === 100 ? "#11B67A" : "#ffae4a"}
                                height='20px'
                                width='100%'
                                animateOnRender={true}
                                isLabelVisible={true}
                                transitionTimingFunction={'ease-in-out'}
                                labelColor='#ffffff'
                                labelSize='10px'
                            />
                        </Col>
                    </Row>
                </Container>
            ),
        },
        {
            name: "Progress Status",
            selector: row => row.progressStatus,
            wrap: true,
            sortable: true,
        },
        {
            name: "Certificate Status",
            selector: row => row.certDownloadStatus,
            wrap: true,
            sortable: true,
        },
        {
            name: "Generate and Download Certificate",
            cell: (row) => (
                (row.progressStatus == 'COMPLETED') ? (<div>
                    <Button onClick={() => {handleCertificateView(row.userId, row.courseId, row.firstName)}} style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)', borderRadius: '5px', border: '0px' }}> 
                    <i class="las la-certificate la-2x" style={{ color: 'white' }}></i> 
                    </Button>
                </div>):(
                    <div>
                        <Button  style={{ background: 'linear-gradient(60deg, #cccccc 0%, #aaaaaa 100%)', borderRadius: '5px', border: '0px' }}> 
                    <i class="las la-certificate la-2x" style={{ color: 'white' }}></i> 
                    </Button>
                    </div>
                )
            ),
            wrap: true,
            sortable: true,
        },

    ];

    const timeSpentColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Type",
            selector: row => row.resTitle,

        },
        {
            name: "Time Spent (HH:MM:SS)",
            selector: row => row.spentTime,
            sortable: true,
            wrap: true
        },
    ];

    const contentAccessColumns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "80px"
        },
        {
            name: "Content Type",
            selector: row => row.resTitle,
            sortable: true,
            wrap: true,
        },
        {
            name: "Start Time",
            selector: row => row.inTime,
            sortable: true,
            wrap: true
        },
        {
            name: "End Time",
            selector: row => row.outTime,
            sortable: true,
            wrap: true
        }
    ];

    const quizReportcolumns = [
        {
            name: "Quiz ID",
            selector: row => row.quizId,
            sortable: true,
            wrap: true,
        },
        {
            name: "Quiz Title",
            selector: row => row.quizTitle,
            sortable: true,
            wrap: true
        },
        {
            name: "Valid From",
            // selector: row => row.validFrom,
            selector: row => moment(row.validFrom).format('MM-DD-YYYY HH:mm'),

            sortable: true,
            wrap: true
        },
        {
            name: "Valid To",
            // selector: row => row.validTo,
            selector: row => moment(row.validTo).format('MM-DD-YYYY HH:mm'),
            sortable: true,
            wrap: true
        },
        {
            name: "Attempts",
            selector: row => row.attempts,
            sortable: true,
            wrap: true
        },
        // {
        //     name: 'Action',
        //     cell: row => <>
        //         <Button onClick={() => { setQuizModal({ show: true }); quizReportInfo(getCurrentUserId.userId, row.quizId); }} disabled={moreDetailsLoading.isLoading ? "true" : ""} style={{ background: "green", border: "0px" }}> {moreDetailsLoading.isLoading ? (<> {t('loading')}</>) : <>{t('more_details')}</>} </Button>
        //     </>
        // }
    ];

    const userContentColumn = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "50px"
        },
        {
            name: "Name",
            selector: row => row.cname,
            width: "250px"
        },
        {
            name: "Type",
            selector: row => row.contentType,

        },
        {
            name: "Published Date",
            selector: row => convertTimestampToDate(row.publishDate),
            width: "230px"

        },
        {
            name: "Progress",
            selector: row => row.activityProgress,
            width: "100px"

        },
        {
            name: "Last Accessed on",
            selector: row => convertTimestampToDate(row.lastAccessed),
            width: "230px"
        },
        {
            name: "Status",
            selector: row => row.activityStatus,
            width: "150px"
        }
    ];


    const onSelectHandler = (e) => {
        setSelectedState(e.target.value);
        if (e.target.value === "3") {
            getQuizReport();
        }

        if (e.target.value === "4") {
            getUserCourseContentWatchReport();
        }
    }

    //console.log("courseList: ", courseList);
    //console.log("courseSummaryList: ", courseSummaryList);
    //console.log("selectedCourseId", selectedCourseId);
    //console.log("filteredItems: ", filteredItems);
    //console.log("totalEnrolledCourse: ", totalEnrolledCourse);
    //console.log("totalInProgressCourse: ", totalInProgressCourse);
    //console.log("totalNotStartedYet: ", totalNotStartedYet);
    //console.log("totalCompletedCourse: ", totalCompletedCourse);
    //console.log("selectedCourse: ", selectedCourse);
    //console.log("selectedCourse: ", selectedCourse);
    //console.log("moreDetailsModalShow: ", moreDetailsModalShow);
    //console.log("selectedRow: ", selectedRow);

    return (
        <div className="container-scroller">
            <Navbar />
            <StickyMenu />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                {loading && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000, // Ensure it's above other elements
                        }}
                    >
                        {/* Loader */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
                        </div>
                    </div>
                )}
                <div className="main-panel">
                    <div className="content-wrapper">
                        
                        <div className="page-header">
                        <h3 className="page-title">Course Summary Report</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}> Course Summary Report</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Analytics Report</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div style={{ padding: "10px" }}>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <label>Select Course</label>
                                                        <Form.Control
                                                            onChange={e => {
                                                                setSelectedCourseId(e.target.value);
                                                                const selectedCourse = courseList.find(
                                                                    (course) => course.courseId === parseInt(e.target.value)
                                                                );
                                                                setSelectedCourse(selectedCourse);
                                                            }}
                                                            as='select'
                                                            placeholder='Select Course'
                                                        >
                                                            <option selected>{t('choose')}</option>
                                                            {
                                                                courseList.map((course, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={
                                                                                course.courseId
                                                                            }>
                                                                            {course.courseName}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                    {
                                                        selectedCourse &&
                                                        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                                                            <p style={{ marginBottom: '8px' }}>Course Instructor: {selectedCourse?.instructor[0]?.firstName}</p>
                                                            <p>Email: {selectedCourse?.instructor[0]?.email}</p>
                                                        </div>
                                                    }
                                                </Col>
                                                {courseSummaryList.length > 0 ?
                                                    <>
                                                        <Col md={4}>
                                                            <div style={styles.summaryContainer}>
                                                                <div style={styles.summaryItem}>
                                                                    <p style={styles.summaryLabel}> Enrolled:</p>
                                                                    <p style={styles.summaryValue}>{totalEnrolledCourse}</p>
                                                                </div>
                                                                <div style={styles.summaryItem}>
                                                                    <p style={styles.summaryLabel}> Completed:</p>
                                                                    <p style={styles.summaryValue}>{totalCompletedCourse}</p>
                                                                </div>
                                                            </div>
                                                            <div style={styles.summaryContainer}>
                                                                <div style={styles.summaryItem}>
                                                                    <p style={styles.summaryLabel}> In Progress:</p>
                                                                    <p style={styles.summaryValue}>{totalInProgressCourse}</p>
                                                                </div>
                                                                <div style={styles.summaryItem}>
                                                                    <p style={styles.summaryLabel}> Not Started Yet:</p>
                                                                    <p style={styles.summaryValue}>{totalNotStartedYet}</p>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md={4}>
                                                            <div style={styles.summaryContainer}>
                                                                <div style={styles.summaryItem}>
                                                                    <p style={styles.summaryLabel}>Total Certficate Generated:</p>
                                                                    <p style={styles.summaryValue}>{totalGenCert}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Button size='sm' onClick={exportExcel} style={{ marginRight: "10px", marginTop: "10px" }}>{t('export_to_excel')}</Button>
                                                                <Button size='sm' onClick={exportPDF} style={{ marginTop: "10px" }}>{t('export_to_pdf')}</Button>
                                                            </div>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>

                                                    </>
                                                }

                                            </Row>
                                            {/* <Button onClick={() => LearnersDatas()} disabled={submitLoading.isLoading ? "true" : ""} style={{background:"green", border:"0px"}}>{submitLoading.isLoading ? (<> Loading...</>) : (<>Submit</>)}</Button> */}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            data={filteredList}
                                            defaultSortField="Name"
                                            defaultSortAsc={true}
                                            striped
                                            pagination
                                            highlightOnHover
                                            pointerOnHover
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={subHeaderComponent}
                                            onRowClicked={handleRowClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SettingsPanel />
                    </div>
                    <Footer />
                </div>
            </div>
            <Modal
                size='xl'
                centered
                show={moreDetailsModalShow}
            >
                <Modal.Header>
                    <h4>More Details</h4>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <br />
                        <h5 style={{ textAlign: "center", fontWeight: 600 }}>{selectedCourse?.courseName}</h5>
                        <br />
                        <h5>Please Select:</h5>
                        <Row className='mt-4'>
                            <div class="col">
                                <div class="col-8 col-md-4">
                                    <select onChange={(e) => onSelectHandler(e)} class="custom-select" id="select">
                                        <option selected value='Choose'>{t('choose')}</option>
                                        <option value="1">{t('time_spent_report')}</option>
                                        <option value="2">{t('content_access_log')}</option>
                                        {/* <option value="3">Quiz Report</option> */}
                                        <option value="4">Content Watch Report</option>
                                    </select>
                                </div>
                            </div>
                        </Row>
                        <br />
                        {
                            selectedState == 1 && (
                                <>
                                    <Container>
                                        <br />
                                        <h6 style={{ textAlign: 'center', fontWeight: 600 }}>{t('time_spend_report')}</h6>
                                        <br></br>
                                        <Row>
                                            <div class="col-8 col-md-4">
                                                <label class="control-label" for="name">{t('from_date')} :</label>
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    step="1"
                                                    id="publishDate"
                                                    name="fromTimeSpendDate"
                                                    value={timeSpendDateRange?.fromTimeSpendDate}
                                                    onChange={timeSpendHandler}
                                                />
                                                {
                                                    timeSpendDateRangeError.fromDateError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{timeSpendDateRangeError.fromDateError}</div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div class="col-8 col-md-4">
                                                <label class="control-label" for="name">{t('to_date')} : </label>
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    title={t('select_course_publish_date')}
                                                    step="1" id="enrollSdate"
                                                    name="toTimeSpendDate"
                                                    value={timeSpendDateRange?.toTimeSpendDate}
                                                    onChange={timeSpendHandler}
                                                />
                                                {
                                                    timeSpendDateRangeError.toDateError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{timeSpendDateRangeError.toDateError}</div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div>
                                                <Button
                                                    style={{ marginTop: '25px', background: "green", border: "0px" }}
                                                    onClick={onClickTimeSpendReportSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Row>
                                        <br></br>
                                        {
                                            timeSpendReportShow
                                                ?
                                                <>
                                                    <div className="card-body">
                                                        <DataTable
                                                            columns={timeSpentColumns}
                                                            data={timespentfilteredItems}
                                                            defaultSortField="Name"
                                                            defaultSortAsc={true}
                                                            striped
                                                            pagination
                                                            highlightOnHover
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={subHeaderComponent}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </Container>

                                </>)
                        }
                        {
                            selectedState == 2 && (
                                <>
                                    <Container>

                                        <br />
                                        <h6 style={{ textAlign: 'center', fontWeight: 600 }}>{t('content_access_log')}</h6>
                                        <br></br>
                                        <Row >
                                            <div class="col-8 col-md-4">
                                                <label class="control-label" for="name">{t('from_date')} : </label>
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    step="1"
                                                    id="publishDate"
                                                    name="fromContentDateRange"
                                                    value={contentDateRange?.fromContentDateRange}
                                                    onChange={contentDateRangeHandler}
                                                />
                                                {
                                                    contentDateRangeError.fromDateError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{contentDateRangeError.fromDateError}</div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div class="col-8 col-md-4">
                                                <label class="control-label" for="name">{t('to_date')} : </label>
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    title={t('select_course_publish_date')}
                                                    step="1"
                                                    id="enrollSdate"
                                                    name="toContentDateRange"
                                                    value={contentDateRange?.toContentDateRange}
                                                    onChange={contentDateRangeHandler}
                                                />
                                                {
                                                    contentDateRangeError.toDateError
                                                        ?
                                                        <div className="alert alert-danger mt-2">{contentDateRangeError.toDateError}</div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div>
                                                <Button style={{ marginTop: '25px', background: "green", border: "0px" }} onClick={onClickContentAcessSubmit}>Submit</Button>
                                            </div>
                                        </Row>
                                        <br></br>
                                        {
                                            contentAccessLogShow
                                                ?
                                                <>
                                                    <div className="card-body">
                                                        <DataTable
                                                            columns={contentAccessColumns}
                                                            data={contentAccessfilteredItems}
                                                            defaultSortField="Name"
                                                            defaultSortAsc={true}
                                                            striped
                                                            pagination
                                                            highlightOnHover
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={subHeaderComponent}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }
                                    </Container>
                                </>
                            )
                        }
                        {
                            selectedState == 3 && (
                                <>
                                    <Container>
                                        <>
                                            <h5 style={{ textAlign: 'center', fontWeight: 600 }}>Quiz Report</h5>
                                            <DataTable
                                                columns={quizReportcolumns}
                                                data={quizfilteredItems}
                                                defaultSortField="Name"
                                                defaultSortAsc={true}
                                                striped
                                                pagination
                                                highlightOnHover
                                                customStyles={customStyles}
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            />
                                        </>
                                        :
                                        <></>

                                    </Container>

                                </>
                            )
                        }
                        {
                            selectedState == 4 && (
                                <>
                                    <Container>
                                        <br />
                                        <h6 style={{ textAlign: 'center', fontWeight: 600 }}>Content Watch Report</h6>
                                        <br></br>
                                        {
                                            userCourseContentReportList.length !== 0
                                                ?
                                                <>
                                                    <div className="card-body">
                                                        <DataTable
                                                            columns={userContentColumn}
                                                            data={userCourseContentReportfilteredList}
                                                            defaultSortField="Name"
                                                            defaultSortAsc={true}
                                                            striped
                                                            pagination
                                                            highlightOnHover
                                                            customStyles={customStyles}
                                                            subHeader
                                                            subHeaderComponent={subHeaderComponent}
                                                        />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                </>
                                        }

                                    </Container>

                                </>)
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleMoreDetailsModalClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default CourseSummary