export const PAID_COURSE_TYPE = "PAID_COURSE_TYPE";
export const FREE_COURSE_TYPE = "FREE_COURSE_TYPE";
export const SEARCH = "SEARCH";
export const CATEGORY = "CATEGORY";
export const SEARCHENGINE = "SEARCHENGINE";
export const COURSETYPE = "COURSETYPE";
export const COURSEDURATION = "COURSEDURATION";
export const COURSEDURATIONLIST = "COURSEDURATIONLIST";
export const COURSEPAYMENTTYPE = "COURSEPAYMENT";
export const COURSERATING_MIN = "COURSERATING_MIN";
export const COURSERATING_MAX = "COURSERATING_MAX";
export const COURSEPAYMENTTYPELIST = "COURSEPAYMENTTYPELIST"
export const COURSERATINGLIST = "COURSERATINGLIST"