import {
  PAID_COURSE_TYPE,
  FREE_COURSE_TYPE,
  SEARCH,
  CATEGORY,
  SEARCHENGINE,
  COURSETYPE,
  COURSEDURATION,
  COURSEPAYMENTTYPE,
  COURSERATING_MIN,
  COURSERATING_MAX,
  COURSEDURATIONLIST,
  COURSEPAYMENTTYPELIST,
  COURSERATINGLIST,
} from "../Types/courseTypes";

export const paidCoursesStatus = (data) => {
  return {
    type: PAID_COURSE_TYPE,
    payload: data,
  };
};

export const freeCoursesStatus = (data) => {
  return {
    type: FREE_COURSE_TYPE,
    payload: data,
  };
};

export const search = (data) => {
  return {
    type: SEARCH,
    payload: data,
  };
};

export const categoryData = (data) => {
  return {
    type: CATEGORY,
    payload: data,
  };
};

export const searchEngine = (data) => {
  return {
    type: SEARCHENGINE,
    payload: data,
  };
};

export const courseType = (data) => {
  return {
    type: COURSETYPE,
    payload: data,
  };
};

export const courseDuration = (data) => {
  return {
    type: COURSEDURATION,
    payload: data,
  };
};

export const courseDurationList = (data) => {
  return {
    type: COURSEDURATIONLIST,
    payload: data,
  };
};

export const coursePaymentType = (data) => {
  return {
    type: COURSEPAYMENTTYPE,
    payload: data,
  };
};

export const coursePaymentTypeList = (data) => {
  return {
    type: COURSEPAYMENTTYPELIST,
    payload: data,
  };
};

export const courseRatingList = (data) => {
  return {
    type: COURSERATINGLIST,
    payload: data,
  };
};


export const courseRatingMin = (data) => {
  return {
    type: COURSERATING_MIN,
    payload: data,
  };
};

export const courseRatingMax = (data) => {
  return {
    type: COURSERATING_MAX,
    payload: data,
  };
};
