import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import './DragDrop.css'; // Import the external CSS file

const DraggableItem = ({ item, index }) => {
  return (
    <Draggable draggableId={item.key} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="draggable-item"
        >
          {item.label}
        </div>
      )}
    </Draggable>
  );
};


const DraggableFolder = ({ folder, index }) => {
  return (
    <Draggable draggableId={folder.key} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="draggable-folder"
        >
          {folder.label}
        </div>
      )}
    </Draggable>
  );
};


const DragAndDropContainer = ({ items, type }) => {
  return (
    <Droppable droppableId={type} type={type}>
      {(provided) => (
        <div
          {...provided?.droppableProps}
          ref={provided?.innerRef}
          className="drag-drop-container"
        >
          {items.map((item, index) => (
            // Render DraggableItem or DraggableFolder based on the item type
            item.nodetype === 'folder' ? (
              <DraggableFolder key={item.key} folder={item} index={index} />
            ) : (
              <DraggableItem key={item.key} item={item} index={index} />
            )
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default DragAndDropContainer