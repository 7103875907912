/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Styles } from "../styles/courseSearch.js";
import { useDispatch, useSelector } from "react-redux";
import {
  search,
  courseDuration,
  coursePaymentType,
  courseRatingMin,
  courseRatingMax,
} from "../../../redux-files/Actions/courseTypeAction.js";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import UserService from "../../../services/UserService.js";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const CourseSearch = (props) => {
  useEffect(() => {
    UserService.generateToken();
  }, []);

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);
  const dispatch = useDispatch();

  const value = useSelector((state) => state.inputValue);
  const courseRating = useSelector((state) => state.courseRating);
  const courseDurationList = useSelector((state) => state.courseDurationList);
  const coursePaymentValue = useSelector((state) => state.coursePaymentType);
  const searchDuration = useSelector((state) => state.courseDuration);
  const coursePaymentList = useSelector((state) => state.coursePaymentTypeList);
  const courseRatingList = useSelector((state) => state.courseRatingList);

  useEffect(() => {
    dispatch(courseDuration(""));
  }, [courseDurationList]);

  const searchPage = (e) => {
    dispatch(search(e.target.value.toLowerCase()));
  };

  const updateCourseDuration = (e) => {
    dispatch(courseDuration(e.target.value));
  };

  const updateCourseFeesType = (e) => {
    dispatch(coursePaymentType(e.target.value));
  };

  const updateCourseMinRating = (e) => {
    dispatch(courseRatingMin(e.target.value));
  };

  const updateCourseMaxRating = (e) => {
    dispatch(courseRatingMax(e.target.value));
  };

  return (
    <Styles>
      <div className="course-search">
        <h5>{t(`${props.name}`)}</h5>
        <form action="#">
          <input
            value={value}
            type="text"
            onChange={searchPage}
            id="search"
            autoComplete="off"
            name="search"
            placeholder={t("search_here")}
          />
          <select value={searchDuration} onChange={updateCourseDuration}>
            <option selected value="">
              Select Duration
            </option>
            {courseDurationList.map((singleDuration) => {
              return <option value={singleDuration}>{singleDuration}</option>;
            })}
          </select>
          <label>Course Type</label>
          <select value={coursePaymentValue} onChange={updateCourseFeesType}>
            {coursePaymentList.map(singlePaymentType => {
              return <option value={singlePaymentType}>{singlePaymentType === "both" ? "Both" : singlePaymentType === "free" ? "Free" : "Paid"}</option>
            })}
          </select>
          <label>Rating</label>
          <div className="select-rating-div">
            <select
              value={courseRating.min}
              onChange={updateCourseMinRating}
              className="select-rating-left"
            >
              {courseRatingList.map(singleRating => {
                return <option value={singleRating}>{singleRating}</option>
              })}
            </select>
            To
            <select
              value={courseRating.max}
              onChange={updateCourseMaxRating}
              className="select-rating-right"
            >
              {courseRatingList.map(singleRating => {
                return <option value={singleRating}>{singleRating}</option>
              })}
            </select>
          </div>
        </form>
      </div>
    </Styles>
  );
};

export default CourseSearch;
