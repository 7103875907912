import React, { useState, useEffect } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import UserService from "../../services/UserService";
import FilterDataTable from "../../pages/instructor/FilterDataTable";
import adminServices from "../../services/adminServices";
import learnerService from "../../services/learnerService";
import service, { USER_API } from "../../services/service";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useHistory } from 'react-router-dom';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

function LearnerReportCard() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const history = useHistory();

  const um_api = USER_API;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const showCourseDetails = (userId) => {

    history.push(`${process.env.PUBLIC_URL + "/single-Learners-Report/single/"}${userId}`);

  }
  const CertificateHide = () => {
    setViewCertificate({ show: false });
    setCertificateLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }

  const IDCardHide = () => {
    setViewID({ show: false });
    setIDLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }
  const ProfilePicHide = () => {
    setViewPhoto({ show: false });
    setPhotoLoading({ isLoading: false });
    //setInfoLoading({ isLoading: false });
  }

  const [getViewCertificate, setViewCertificate] = useState({
    show: false
  });
  const [getViewPhoto, setViewPhoto] = useState({
    show: false
  });
  const [IDLoading, setIDLoading] = useState({
    isLoading: false
  })
  const [CertificateLoading, setCertificateLoading] = useState({
    isLoading: false
  })

  const [PhotoLoading, setPhotoLoading] = useState({
    isLoading: false
  })
  const [getViewID, setViewID] = useState({
    show: false
  });

  const [PhotoEmail, setPhotoEmail] = useState();
  const [IDEmail, setIDEmail] = useState();
  const [CertificateEmail, setCertificateEmail] = useState();

  const viewPhoto = (profilepicpath, email, learnername, imagepath) => {
    setPhotoEmail(email);
    setPhotoLoading({ isLoading: true });
    setViewPhoto({
      show: true,
      learnername: learnername,
      imagepath: imagepath,
      profilepicpath: profilepicpath,
    });
  }
  const viewID = (idproofpicpath, email) => {
    setIDEmail(email);
    setIDLoading({ isLoading: true });
    setViewID({
      show: true,
      idproofpicpath: idproofpicpath,
    });
  }

  const viewCertificate = (certificatepath, email) => {
    setCertificateEmail(email);
    setCertificateLoading({ isLoading: true });
    setViewCertificate({
      show: true,
      certificatepath: certificatepath,
    });
  }

  const [submitLoading, setSubmitLoading] = useState({
    isLoading: false,
  });

  const [selectedName, setSelectedName] = useState();
  

  const columns = [
    {
      name: "Learner",
      cell: (row) => (
        <img
          src={um_api + `getprofilepic/${row.learnerUsername}`}
          style={{
            width: "42px",
            height: "42px",
            borderRadius: "100%",
            boxShadow: "5px 10px 20px 1px rgba(0, 0, 0, 0.253)",
          }}
        />
      ),
      sortable: true,
    //   width: "100px",
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      wrap: true,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Documents",
      //selector: row => row.gpfno,
      sortable: true,
      cell: (row) => <div>
        <div className="d-inline-block">
          <button
            onClick={() => viewPhoto(row.idproofpicpath, row.email, row.learnerUsername, row.profilepicpath)}
            type="button" class="btn btn-info m-2" disabled={PhotoLoading.isLoading ? "true" : ""}>
            {PhotoLoading.isLoading ? PhotoEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-image fa-2x"></i></>) : (<><i class="fas fa-image fa-2x"></i></>)}
          </button>
          <button
            onClick={() => viewID(row.idproofpicpath, row.email)}
            type="button" class="btn btn-info m-2" disabled={IDLoading.isLoading ? "true" : ""}>
            {IDLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>) : (<><i class="fas fa-id-card fa-2x"></i></>)}
          </button>
          <button
            onClick={() => viewCertificate(row.certificatepath, row.email)}
            type="button" class="btn btn-info m-2" disabled={CertificateLoading.isLoading ? "true" : ""}>
            {CertificateLoading.isLoading ? IDEmail === row.email ? (<><i class="fa fa-spinner fa-spin" style={{ fontSize: '24px' }}></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>) : (<><i class="fa fa-certificate fa-2x"></i></>)}
          </button>
        </div>
      </div>

    },
    {
      name: "Action",
      sortable: true,
      wrap: true,
      cell: (row) => <div>
        <button
      type="button"
      onClick={() => {
        showCourseDetails(row.learnerUsername);
      }}
      class="btn btn-info m-2"
    >
      {submitLoading.isLoading ? (
        selectedName === row.learnerUsername ? (
          <>{t("loading")}</>
        ) : (
          <>
            <i class="fa fa-file"></i> 
          </>
        )
      ) : (
        <>
          <i class="fa fa-file"></i>
        </>
      )}
    </button></div>,
    },
  ];

  const [getLearnerData, setlearnerData] = useState([]);
  useEffect(() => {
    LearnersDatas();
  }, []);

  const LearnersDatas = () => {
    adminServices
      .getAllLearners()
      .then((resp) => {
        setlearnerData(resp.data);
      })
      .catch((err) => {});
  };

  const filteredItems = getLearnerData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div>
              <div className="page-header">
                <h3 className="page-title">Learners Report</h3>
              </div>
              <Col lg="12">
                <div className="card">
                <div className="card-body">
                  <div style={{ padding: "10px" }}>
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    defaultSortField="Name"
                    defaultSortAsc={true}
                    striped
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    subHeader
                    subHeaderComponent={subHeaderComponent}
                  />
                </div>
                </div>
                </div>
              </Col>
            </div>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
      <Modal centered show={getViewID.show} onHide={() => IDCardHide()} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('id_card')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewID.idproofpicpath && (
              <img
                className="img-fluid rounded"
                src={um_api + `getidforadminverification/${getViewID.idproofpicpath}`}
                style={{ maxWidth: '100%' }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => IDCardHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={getViewPhoto.show} onHide={() => ProfilePicHide()} backdrop="static" size="md">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('photo')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewPhoto.imagepath ? (
              <img
                className="img-fluid rounded"
                src={um_api + `getprofilepic/${getViewPhoto.learnername}`}
                style={{ maxWidth: '100%' }}
              />
            ) : (<img
              className="img-fluid rounded"
              src={um_api + `getprofilepicforadminverification/${getViewPhoto.profilepicpath}`}
              style={{ maxWidth: '100%' }}
            />)}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => ProfilePicHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={getViewCertificate.show} onHide={() => CertificateHide()} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" style={{ alignContent: "center" }}>
            {t('id_card')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ textAlign: "center" }}>
            {getViewCertificate.certificatepath && (
              <img
                className="img-fluid rounded"
                src={um_api + `getcertificateforadminverification/${getViewCertificate.certificatepath}`}
                style={{ maxWidth: '100%' }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "right" }}>
          <Button variant="secondary" onClick={() => CertificateHide()}>
            {t('cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LearnerReportCard;
