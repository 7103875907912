import React, { useEffect, useState } from 'react'
import { Button, Collapse, Container, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./ContentDeliveryNav.css";
import Truncate from 'react-truncate';

const ContentDeliveryNav = ({ mergedCourseStructure, onToggleSideBar, CourseDetailsRedirect, sideBarOpen }) => {

    const [truncateWidth, setTruncateWidth] = useState(getTruncateWidth());

    useEffect(() => {
        function handleResize() {
            setTruncateWidth(getTruncateWidth());
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function getTruncateWidth() {
        const screenWidth = window.innerWidth;

        if (screenWidth <= 370) {
            return 100;
        } else if (screenWidth <= 650) {
            return 200;
        } else if (screenWidth <= 1024) {
            return 450;
        } else {
            return 550;
        }
    }

    return (
        <nav
            id='content-delivery-nav-main'
            className="navbar content-delivery-navbar">
            <div className="container-fluid">
                <div
                    className={`content-delivery-navbar-toggler ${sideBarOpen ? 'open' : ''}`}
                    type="button"
                    onClick={() => onToggleSideBar()}
                >
                    <span className={`navbar-toggler-icon ${sideBarOpen ? 'open' : ''}`}>
                        {sideBarOpen ?
                            <>
                                <i class="fa fa-2x fa-plus" aria-hidden="true"></i>
                            </>
                            :
                            <>
                                <i class="fa fa-2x fa-bars" aria-hidden="true"></i>
                            </>}
                    </span>
                </div>
                <span className="content-delivery-navbar-heading">
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip
                                id=""
                                style={{ fontSize: "16px" }}
                            >
                                {mergedCourseStructure?.label}
                            </Tooltip>
                        }
                    >
                        <span id='label-name'>
                            <Truncate
                                lines={1}
                                width={truncateWidth}
                                ellipsis={"...."}
                            >
                                <div className=''>
                                    {mergedCourseStructure?.label}
                                </div>
                            </Truncate>
                        </span>
                    </OverlayTrigger>
                </span>
                <div
                    className="content-delivery-close-btn"
                    onClick={() => {
                        CourseDetailsRedirect();
                    }}>
                    Close
                </div>
            </div>
        </nav>
    );
}

export default ContentDeliveryNav