import React, { useState, useEffect } from 'react';
import "./ContentDeliveryArea.css";
import service, { Assessment_Delivery, DMS, DMS_URL, Frontend_UI } from '../../../../services/service';
import axios from 'axios';
import CryptoJS from "crypto-js";
import ProgressBar from '@ramonak/react-progress-bar';
import { Circles } from 'react-loader-spinner';
import ViewPdf from '../../../instructor/ViewPdf';
import ReactPlayer from 'react-player';
import MediaContentView from '../../components/MediaContentView';

const ContentDeliveryArea = ({
  mergedCourseStructure,
  selectedContent,
  sideBarOpen,
  userId,
  courseId,
  sessionId,
  url,
  showContent,
  activityProgress,
  videoEncodeStatus,
  contentLoader,
}) => {
  //console.log("URL: ", url);

  const [externalLinkOpened, setExternalLinkOpened] = useState(false);

  useEffect(() => {
    setExternalLinkOpened(false);
  }, [selectedContent]);

  useEffect(() => {
    if (selectedContent?.nodetype === "externalLink" && url && showContent && selectedContent?.activityProgressforDependency === 100 && !externalLinkOpened) {
      setExternalLinkOpened(true);
      window.open(url, "_blank");
    }
  }, [selectedContent, url, showContent, externalLinkOpened]);

  const ContentDisplay = () => {

    if (!showContent) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* <Circles
              height={40}
              width={40}
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> */}
          </div>
        </div>
      )
    }
    else if (selectedContent?.nodetype === "VLabs" && showContent) {

      return (
        (selectedContent?.activityProgressforDependency === 100) ? (
          <>
            <div>
              Check next tab
            </div>
          </>
        ) : (
          <>
            <h6>
              Complete previous contents
            </h6>
          </>
        ));
    }
    else if (selectedContent?.nodetype === "mp4" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}

            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (

            videoEncodeStatus == "success" && url != "" ? (
              <div>
                <MediaContentView cmsUrl={url} />
              </div>
            ) : (
              <div>
                <video width="100%" height="100%" controls controlsList="nodownload noplaybackrate" onContextMenu={(e) => e.preventDefault()}>
                  <source
                    src={selectedContent?.activityProgressforDependency === 100 ? `${DMS_URL}/${url}` : "#"}
                    type="video/mp4"
                  />
                </video>
              </div>
            )
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}
        </>
      );
    } else if ((selectedContent?.nodetype === "png" || selectedContent?.nodetype === "jpg" || selectedContent?.nodetype === "jpeg") && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>

          {(selectedContent?.activityProgressforDependency === 100) ? (
            <div>
              <figure>
                <img
                  src={selectedContent?.activityProgressforDependency === 100 ? `${DMS_URL}/${url}` : "#"}
                  width="100%"
                  height="100%"
                />
              </figure>
            </div>
          ) : (
            <div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}

        </>
      );
    } else if ((selectedContent?.nodetype === "scorm" || selectedContent?.nodetype === "html") && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (
            <div>
              <object
                data={selectedContent?.activityProgressforDependency === 100 ? `${DMS_URL}/${url}` : "#"}
                width="100%" height="700" type="text/html">
              </object>
              {/* <iframe
                width="1100"
                height="800"
                src={DMS_URL + `/${url}`}
              ></iframe> */}
            </div>) : (<div>
              <h6>
                Complete previous contents
              </h6>
            </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "zip" && url && showContent) {
      return (

        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe
              src={selectedContent?.activityProgressforDependency === 100 ? `${DMS_URL}/${url}` : "#"}
              width="100%"
              height="700px"
              allowFullScreen={true}
              style={{ minHeight: "700px", overflow: "auto" }}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>


      );
    }

    else if (selectedContent?.nodetype === "practiceQuiz" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
            <div style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'bold' }}>
              Redirecting, Please Wait...
            </div>
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ?
            <>
              {/* {window.location.replace(Assessment_Delivery + `/${url}`)} */}
              {setTimeout(() => {
                window.location.replace(Assessment_Delivery + `/${url}`);
              }, 1000)}
            </>
            : <>
              <div>
                <h6>
                  Complete previous contents
                </h6>
              </div>
            </>
          }
        </>
      );
    }

    else if (selectedContent?.nodetype === "pdf" && url && showContent) {
      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ViewPdf pdfUrl={`${Frontend_UI}/pdfViewer/${url}`} />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>
      );
    }
    else if (selectedContent?.nodetype === "youtube" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <ReactPlayer
              url={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="700px"
              controls="true"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 }
                }
              }}
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "streaming" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress == 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {(selectedContent?.activityProgressforDependency === 100) ? (<div>
            <iframe
              title="Embedded HTML Page"
              src={selectedContent?.activityProgressforDependency === 100 ? `${url}` : "#"}
              width="100%"
              height="800"
              allowFullScreen
            />
          </div>) : (<div>
            <h6>
              Complete previous contents
            </h6>
          </div>)}
        </>

      );
    }
    else if (selectedContent?.nodetype === "externalLink" && url && showContent) {

      return (
        <>
          <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
            <ProgressBar
              completed={activityProgress || 0}
              maxCompleted={100}
              bgColor={activityProgress === 100 ? "#11B67A" : "#f0ad4e"}
              height='18px'
              animateOnRender={true}
              transitionTimingFunction={'ease-in-out'}
            />
          </div>
          {selectedContent?.activityProgressforDependency === 100 ? (
            <h6>Link will open in new tab...</h6>
          ) : (
            <h6>Complete previous contents</h6>
          )}
        </>
      );
    }
    else {
      return (
        <div></div>
      )
    }
  };

  // useEffect(() => {
  //   ContentDisplay();
  //   //console.log("ContentDisplay useeffcetCalled");
  // }, [url])

  //console.log("URL: ", url);
  //console.log("CONTENT AREA selectedContent: ", selectedContent);
  //console.log("CONTENT AREA previousContent: ", selectedContent);
  //console.log("updatedSelectedContentData:", updatedSelectedContentData);
  //console.log("activityProgress: ", activityProgress);
  //console.log("intervalDuration: ", intervalDuration);


  return (
    <div className='content-delivery-area-main'>
      <div className={`content-display-area ${sideBarOpen ? '' : 'open'}`}>

        {Object.keys(selectedContent).length == 0 && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            < h3 > Please Select a Content</h3>
          </div>
        )}
        {
          selectedContent && (
            <div className='content-display'>
              {ContentDisplay()}
            </div>
          )}
      </div>

    </div >
  )
}

export default ContentDeliveryArea